.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  text-align: center;
}

.App-header {
  display: none;
  width: 100wh;
  height: 50px;
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-navigation {
  z-index: 3;
}

@keyframes filter-color {
  from {
    filter: url(#grayscale);
  }
  to {
    filter: url(#colorize); /* 컬러 필터 적용 */
  }
}

.glassmorphism {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50vw;
  height: 30vh;
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(12.5px);
  -webkit-backdrop-filter: blur(12.5px);
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.center {
  margin: auto;
}
