/* @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap"); */
/* Noto Sans Korea: 가장 기본적인 폰트
<uniquifier>: Use a unique and descriptive class name
<weight>: Use a value from 100 to 900

.noto-sans-kr-<uniquifier> {
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal; 
*/

/* @import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap"); */
/* Oswald: 영문 타이틀 폰트
<uniquifier>: Use a unique and descriptive class name
<weight>: Use a value from 200 to 700

.oswald-<uniquifier> {
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
}
*/

/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"); */
/* Montserrat: 영문 타이틀 폰트
// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900

.montserrat-<uniquifier> {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
}


*/

/* @import url("https://fonts.googleapis.com/css2?family=Nanum+Gothic+Coding&display=swap"); */
/* Nanum Fothic Coding: 작은 폰트, 박스 안에 꽉 채우는 상세 설명에 사용
<weight>: Use a value from 400 or 700
.nanum-gothic-coding-regular {
  font-family: "Nanum Gothic Coding", monospace;
  font-weight: 400;
  font-style: normal;
}

*/

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-black: #000000;
  --color-white: #ffffff;
  --color-point: rgb(255, 155, 0, 08);
  --color-gray: #555555;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans KR", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Nanum Gothic Coding", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

a {
  text-decoration: none;
  pointer-events: auto;
  outline: none;
  color: inherit;
}

.transition {
  transition: all cubic-bezier(0.36, 0, 0.22, 1.17) 0.3s;
}

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

:root {
}
