.nav_title p {
  font-size: 1.2rem;
  margin: 0;
}

.wrapper {
  position: fixed;
  z-index: 3;
}
.nav_ul {
}
.nav_li,
.nav_title,
.nav_contents {
  transition: all cubic-bezier(0.36, 0, 0.22, 1.17) 0.3s;
}

.nav_title {
  cursor: pointer;
}

.nav_li:hover > div {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
}

/* 타이틀에 호버됐을 때 컨텐츠에 효과 적용, 타이틀 호버 아웃 되고 컨텐트로 호버되면 컨텐트에 호버효과 적용 */
/* 
.nav_title:hover + .nav_contents,
.nav_contents:hover {
  visibility: visible;
  height: calc(100vh - 1.2rem - 41px);
  opacity: 1;
} */

.nav_title_hover + .nav_contents_hover,
.nav_contents_hover {
  visibility: visible;
  height: calc(100vh - 1.2rem - 41px);
  opacity: 1;
}

/*
 
모바일 버전 css 
 
 */
.mNav {
  width: 100vw;
  height: 3.8rem;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
}
.mHeader {
  padding: 0.5rem 1rem;
  display: flex;
  font-size: 1.8rem;
  text-align: left;
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  justify-content: space-between;
}
.mMenu {
  width: 3rem;
  height: 3rem;
}

/* 메뉴바 인터렉션 */
.menuIcon {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 0.3em;
  width: 1.9rem;
  height: 1.9rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
}
.menuIcon:focus {
  outline: none;
}
.bar {
  width: 30px;
  height: 3px;
  background-color: #333;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}
.open .bar:first-child {
  transform: rotate(45deg);
}
.open .bar:nth-child(2) {
  opacity: 0;
  transform: translateX(20px);
}
.open .bar:nth-child(3) {
  transform: rotate(-45deg);
}

.mList {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: calc(100vh - 3.8rem);
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
  transition: all 0.3s cubic-bezier(0.8, 0, 0.2, 1);
  font-size: 2rem;
  font-weight: 700;
}
.listOpen {
  height: 0;
  transform: translateX(100%);
}
