.wrapper {
}

.item {
  position: relative;
  display: block;
}
/* 이미지 */
.item > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.item:hover img {
  opacity: 0.1;
}

.title,
.desc {
  text-shadow: 0 0 2px white;
  transition: all 0.3s ease;
}

.title,
.desc {
  display: inline-block;
  transition: all 0.3s ease;
}

.item:hover .title,
.item:hover .desc {
  opacity: 1;
  transform: translateY(-100%) scaleY(0);
}

.textwrapper {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: "Nanum Gothic Coding", monospace;
  font-style: normal;
  font-weight: 700;
  color: black;
  text-shadow: 0 0 2px white;
}
.textwrapper:nth-child(1) {
  padding: 1rem 0 0 1rem;
  line-height: 1.2;
}
.textwrapper:nth-child(2) {
  padding: 0 0 0 1rem;
}
.item div:before {
  content: attr(data-hover);
  text-align: left;
  position: absolute;
  opacity: 0;
  width: 100%;
  text-shadow: 0 0 5px white;
  transform: translateY(100%) scaleY(0);
  transition: all 0.3s ease;
}

.item:hover div:before {
  opacity: 1;
  transform: translateY(0%) scaleY(1);
}

/* 태블릿 및 작은 화면 디바이스를 위한 미디어 쿼리 */
@media (max-width: 768px) {
}

/* 더 작은 모바일 디바이스를 위한 미디어 쿼리 */
@media (max-width: 480px) {
}
